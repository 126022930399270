import moment from 'moment-timezone';
import appConfig from '@/config/app';
import { authenticate } from '../mix/authenticate';
import ApiService from "@/services/api";
import WorkerService from "@/services/worker";
import BroadcastService from "@/services/broadcast";
import action from "@/store/core/action";

import {
  saveSession,
  destroySession,
  saveUserDataSession,
} from "@/services/oauth";

import {
  LOAD
} from "../app/actions";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_REMEMBER,
  LOGIN_FAIL,
  LOGIN_REFRESH,
  LOGIN_PURGE,
  LOGIN_RESTORE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_AUTH_USER,
  GET_AUTH_USER_SUCCESS,
  GET_AUTH_USER_FAIL,
  UPDATE_AUTH_USER_LOCALE,
  UPDATE_AUTH_USER_LOCALE_SUCCESS,
  UPDATE_AUTH_USER_LOCALE_FAIL,
  FORGET_AUTH_USER,
  ACCEPT_AUTH_USER_TERMS,
  ACCEPT_AUTH_USER_TERMS_SUCCESS,
  ACCEPT_AUTH_USER_TERMS_FAIL,
  SUBSCRIBE_AUTH_USER,
  UNSUBSCRIBE_AUTH_USER,
  CHECK_AUTH_USER_SUBSCRIPTION,
  CHECK_AUTH_USER_SUBSCRIPTION_SUCCESS,
  CHECK_AUTH_USER_SUBSCRIPTION_FAIL,
  GET_AUTH_USER_NOTIFICATIONS,
  GET_AUTH_USER_NOTIFICATIONS_SUCCESS,
  GET_AUTH_USER_NOTIFICATIONS_FAIL,
  READ_AUTH_USER_NOTIFICATIONS,
  READ_AUTH_USER_NOTIFICATIONS_SUCCESS,
  READ_AUTH_USER_NOTIFICATIONS_FAIL,
  UNREAD_AUTH_USER_NOTIFICATIONS,
  UNREAD_AUTH_USER_NOTIFICATIONS_SUCCESS,
  UNREAD_AUTH_USER_NOTIFICATIONS_FAIL,
  READ_ALL_AUTH_USER_NOTIFICATIONS,
  READ_ALL_AUTH_USER_NOTIFICATIONS_SUCCESS,
  READ_ALL_AUTH_USER_NOTIFICATIONS_FAIL,
  BROADCAST_AUTH_USER_NOTIFICATIONS,
  BROADCAST_AUTH_USER_NOTIFICATIONS_SUCCESS,
  BROADCAST_AUTH_USER_NOTIFICATIONS_FAIL,
  SEND_RESET_PASSWORD_LINK,
  SEND_RESET_PASSWORD_LINK_RESET,
  SEND_RESET_PASSWORD_LINK_SUCCESS,
  SEND_RESET_PASSWORD_LINK_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
} from "./actions";

import {
  FORGET_SETTINGS
} from "../settings/actions";
import { reject } from 'lodash';

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'sendResetPasswordLinkRequest',
    action: SEND_RESET_PASSWORD_LINK,
    actionReset: SEND_RESET_PASSWORD_LINK_RESET,
    endPoint: 'auth/reset-password/send',
    mutations: {
      fail: SEND_RESET_PASSWORD_LINK_FAIL,
      success: SEND_RESET_PASSWORD_LINK_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'updatePasswordRequest',
    action: UPDATE_PASSWORD,
    actionReset: UPDATE_PASSWORD_RESET,
    endPoint: 'auth/reset-password/update',
    mutations: {
      fail: UPDATE_PASSWORD_FAIL,
      success: UPDATE_PASSWORD_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  loginRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {}
  },
  logoutRequest: {
    loading: false
  },
  registerRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {}
  },
  getUserRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {}
  },
  updateUserLocaleRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {}
  },
  acceptTermsRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {}
  },
  getNotificationsRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {},
    notifications: []
  },
  readNotificationRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {},
  },
  unreadNotificationRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {},
  },
  readAllNotificationsRequest: {
    loading: false,
    success: false,
    fail: false,
    errors: {},
  },
  userData: {},
  isSubscribed: false,
  isAuthenticated: false,
  ...actionsProcessed.state
};

const actions = {
  [LOGIN](context, { email, password, isRemember }) {
    context.state.loginRequest.loading = true;

    return new Promise(resolve => {
      ApiService.post("auth/session", { email, password })
        .then(response => {
          if (!isRemember) {
            saveSession(response.data.data);
          } else {
            saveSession(response.data.data, true);
          }

          authenticate(context).then(() => {
            context.commit(LOGIN_SUCCESS, response.data.data);
            resolve(response.data);
          });
        })
        .catch(({ response }) => {
          context.commit(LOGIN_FAIL, response.data);
        });
    });
  },
  [LOGOUT](context) {
    context.state.logoutRequest.loading = true;

    return new Promise(resolve => {
      ApiService.post("auth/session/destroy")
        .then(() => {
          context.commit(LOGOUT_SUCCESS);

          context.dispatch(FORGET_AUTH_USER);
          context.dispatch(FORGET_SETTINGS);
          resolve();
        })
        .catch(() => {
          context.commit(LOGOUT_FAIL);
        });
    });
  },
  [REGISTER](context, data) {
    context.state.registerRequest.loading = true;

    return new Promise((resolve, reject) => {
      ApiService.post("auth/register", data)
        .then(response => {
          context.commit(REGISTER_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(({ response }) => {
          context.commit(REGISTER_FAIL, response.data);
          reject(response.data);
        });
    });
  },
  [LOGIN_REFRESH](context, refreshToken) {
    return new Promise(resolve => {
      ApiService.post("auth/session/refresh", {
        refreshToken
      })
        .then(response => {
          context.commit(LOGIN_SUCCESS_REMEMBER, response.data.data);
          resolve(response.data.data);
        })
        .catch(response => {
          context.commit(LOGIN_FAIL, response.data);
        });
    });
  },
  [LOGIN_PURGE](context) {
    context.commit(LOGOUT_SUCCESS);

    context.dispatch(FORGET_AUTH_USER);
    context.dispatch(FORGET_SETTINGS);
  },
  [LOGIN_RESTORE](context) {
    context.state.isAuthenticated = true;
  },
  [GET_AUTH_USER](context) {
    context.state.getUserRequest.loading = true;

    return new Promise(resolve => {
      ApiService.get("auth/user")
        .then(response => {
          context.commit(GET_AUTH_USER_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(GET_AUTH_USER_FAIL, response);
        });
    });
  },
  [UPDATE_AUTH_USER_LOCALE](context, { locale }) {
    context.state.updateUserLocaleRequest.loading = true;

    return new Promise(resolve => {
      ApiService.put("auth/update/locale", {
        locale
      })
        .then(response => {
          context.commit(UPDATE_AUTH_USER_LOCALE_SUCCESS, response.data.data);
          context.dispatch(LOAD);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(UPDATE_AUTH_USER_LOCALE_FAIL, response);
        });
    });
  },
  [FORGET_AUTH_USER](context) {
    context.state.userData = {};
  },
  [ACCEPT_AUTH_USER_TERMS](context) {
    context.state.acceptTermsRequest.loading = true;

    return new Promise(resolve => {
      ApiService.post("auth/accept-terms")
        .then(response => {
          context.commit(ACCEPT_AUTH_USER_TERMS_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(ACCEPT_AUTH_USER_TERMS_FAIL, response);
        });
    });
  },
  [SUBSCRIBE_AUTH_USER](context) {
    WorkerService.activateSubscription().then(() => {
      context.commit(CHECK_AUTH_USER_SUBSCRIPTION_SUCCESS, true);
    });
  },
  [UNSUBSCRIBE_AUTH_USER](context) {
    WorkerService.unsubscribeUser().then(() => {
      context.commit(CHECK_AUTH_USER_SUBSCRIPTION_SUCCESS, false);
    });
  },
  [CHECK_AUTH_USER_SUBSCRIPTION](context, params) {
    return WorkerService.isSubscribed().then(subscription => {
      context.commit(CHECK_AUTH_USER_SUBSCRIPTION_SUCCESS, !!subscription);
    }).catch((e) => {
      console.error(e);
      context.commit(CHECK_AUTH_USER_SUBSCRIPTION_FAIL);
    });
  },
  [GET_AUTH_USER_NOTIFICATIONS](context, params) {
    context.state.getNotificationsRequest.loading = true;

    return new Promise(resolve => {
      ApiService.query("auth/notifications", params)
        .then(response => {
          context.commit(GET_AUTH_USER_NOTIFICATIONS_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(GET_AUTH_USER_NOTIFICATIONS_FAIL, response);
        });
    });
  },
  [READ_AUTH_USER_NOTIFICATIONS](context, id) {
    context.state.readNotificationRequest.loading = true;

    return new Promise(resolve => {
      ApiService.post(`auth/notifications/read/${id}`)
        .then(response => {
          context.commit(READ_AUTH_USER_NOTIFICATIONS_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(READ_AUTH_USER_NOTIFICATIONS_FAIL, response);
        });
    });
  },
  [UNREAD_AUTH_USER_NOTIFICATIONS](context, id) {
    context.state.unreadNotificationRequest.loading = true;

    return new Promise(resolve => {
      ApiService.post(`auth/notifications/unread/${id}`)
        .then(response => {
          context.commit(UNREAD_AUTH_USER_NOTIFICATIONS_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(UNREAD_AUTH_USER_NOTIFICATIONS_FAIL, response);
        });
    });
  },
  [READ_ALL_AUTH_USER_NOTIFICATIONS](context) {
    context.state.readAllNotificationsRequest.loading = true;

    return new Promise(resolve => {
      ApiService.post(`auth/notifications/read-all`)
        .then(response => {
          context.commit(READ_ALL_AUTH_USER_NOTIFICATIONS_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(response => {
          context.commit(READ_ALL_AUTH_USER_NOTIFICATIONS_FAIL, response);
        });
    });
  },
  [BROADCAST_AUTH_USER_NOTIFICATIONS](context) {
    BroadcastService.notificationPrivate(
      `users.notifications.${context.state.userData.id}`,
      notification => {
        context.commit(BROADCAST_AUTH_USER_NOTIFICATIONS_SUCCESS, notification);
      }
    );
  },
  ...actionsProcessed.actions
};

const mutations = {
  [LOGIN_SUCCESS](state, data) {
    state.isAuthenticated = true;

    state.loginRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {}
    };
  },
  [LOGIN_FAIL](state, data) {
    state.loginRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors
    };
  },
  [LOGOUT_SUCCESS](state) {
    state.isAuthenticated = false;

    state.logoutRequest = {
      loading: false
    };

    destroySession();
  },
  [LOGOUT_FAIL](state) {},
  [REGISTER_SUCCESS](state, data) {
    state.registerRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {}
    };
  },
  [REGISTER_FAIL](state, data) {
    state.registerRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors
    };
  },
  [GET_AUTH_USER_SUCCESS](state, data) {
    state.getUserRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {}
    };

    if (data.date_format) {
      appConfig.format.date = data.date_format;
    }

    state.userData = data;

    saveUserDataSession(data);
  },
  [GET_AUTH_USER_FAIL](state, data) {
    state.getUserRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors
    };

    state.userData = {};
  },
  [UPDATE_AUTH_USER_LOCALE_SUCCESS](state, data) {
    state.updateUserLocaleRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {}
    };
  },
  [UPDATE_AUTH_USER_LOCALE_FAIL](state, data) {
    state.updateUserLocaleRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors
    };
  },
  [ACCEPT_AUTH_USER_TERMS_SUCCESS](state, data) {
    state.acceptTermsRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {}
    };

    state.userData = data;
  },
  [ACCEPT_AUTH_USER_TERMS_FAIL](state, data) {
    state.acceptTermsRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors
    };
  },
  [CHECK_AUTH_USER_SUBSCRIPTION_FAIL](state) {
    state.isSubscribed = false;
  },
  [CHECK_AUTH_USER_SUBSCRIPTION_SUCCESS](state, isSubscribed) {
    state.isSubscribed = isSubscribed;
  },
  [GET_AUTH_USER_NOTIFICATIONS_SUCCESS](state, data) {
    state.getNotificationsRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {},
      notifications: data.collection,
    };
  },
  [GET_AUTH_USER_NOTIFICATIONS_FAIL](state, data) {
    state.getNotificationsRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors,
      notifications: []
    };
  },
  [READ_AUTH_USER_NOTIFICATIONS_SUCCESS](state, data) {
    state.readNotificationRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {},
    };
    state.getNotificationsRequest.notifications.forEach(notification => {
      if (notification.id === data.id) {
        notification.read_at = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
      }
    });
  },
  [READ_AUTH_USER_NOTIFICATIONS_FAIL](state, data) {
    state.readNotificationRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors,
    };
  },
  [UNREAD_AUTH_USER_NOTIFICATIONS_SUCCESS](state, data) {
    state.unreadNotificationRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {},
    };

    state.getNotificationsRequest.notifications.forEach(notification => {
      if (notification.id === data.id) {
        notification.read_at = null;
      }
    });
  },
  [UNREAD_AUTH_USER_NOTIFICATIONS_FAIL](state, data) {
    state.unreadNotificationRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors,
    };
  },
  [READ_ALL_AUTH_USER_NOTIFICATIONS_SUCCESS](state) {
    state.readAllNotificationsRequest = {
      loading: false,
      success: true,
      fail: false,
      errors: {},
    };

    state.getNotificationsRequest.notifications.forEach(notification => {
      notification.read_at = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
    })
  },
  [READ_ALL_AUTH_USER_NOTIFICATIONS_FAIL](state, data) {
    state.readAllNotificationsRequest = {
      loading: false,
      success: false,
      fail: true,
      errors: data.errors,
    };
  },
  [BROADCAST_AUTH_USER_NOTIFICATIONS_SUCCESS](state, data) {
    const notification = {
      data,
      id: data.id,
      type: data.type,
      read_at: null,
      created_at: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss'),
    };

    state.getNotificationsRequest.notifications = [
      notification,
      ...state.getNotificationsRequest.notifications,
    ];
  },
  ...actionsProcessed.mutations
};

export default {
  state,
  actions,
  mutations,
  getters
};
